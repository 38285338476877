import React from "react";
import { Link } from "gatsby";

const NotFound = () => {
  return (
    <div className="spacing">
      <div className="content text-center">
        <h1>Page Not Found</h1>
        <p>
          The page that you are looking for was not found.{" "}
          <Link to="/">Return home?</Link>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
